import React, {useState} from "react";
import { BrowserRouter , Router, Routes, Route, NavLink, Switch, Link } from "react-router-dom";
import Home from "./Home";
import AboutPage from "./About";
import ServicePage from "./ServicesPage";
import PortfolioPage from "./PortfolioPage";
import WhyPage from "./WhyUsPage";
import ContactUs from "./Contactus";
import Privacy from "./Privacy";
import ReqForm from "./ReqForm";
import { MdOutlineMenu } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import thankyou from "./Thank-you";

const NavBar = () => {
  const [selectedItem, setSelectedItem] = useState('Collections');
  const [isListOpen, setIsListOpen] = useState(false);

  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsListOpen(false);
  };

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  return (
    <>
    <div className="2xl:container 2xl:mx-auto">
      <div className="bg-white flex rounded shadow-lg py-2 px-7">
        <nav className="flex w-full justify-between">
        <div className="flex items-center sm:w-[140px] space-x-3 lg:pr-8 pr-6">
        <NavLink to="/">
            <img
            className="h-[70px] w-[120px]"
             src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/Core_Spectators_1.png?v=1719032020"/>
           </NavLink>
          </div>
         
          {/* For medium and plus sized devices */}
          <ul className="hidden md:flex flex-auto m-auto space-x-4">
          <NavLink to="/">
            <li
              onClick={() => handleSelect('Collections')}
              className={`focus:outline-none focus:ring-2 h-max focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-base leading-3 shadow-md rounded ${
                selectedItem === 'Collections'
                  ? 'text-white bg-red-600'
                  : 'text-gray-600 border border-white bg-gray-50'
              }`}
            >
              Home
            </li>
            </NavLink>
            <NavLink to="/About">
            <li
              onClick={() => handleSelect('Arts')}
              className={`focus:outline-none focus:ring-2 h-max focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-base leading-3 shadow-md rounded ${
                selectedItem === 'Arts'
                  ? 'text-white bg-red-600'
                  : 'text-gray-600 border border-white bg-gray-50'
              }`}
            >
              About us
            </li>
            </NavLink>
            <NavLink to="/Services">
            <li
              onClick={() => handleSelect('Space')}
              className={`focus:outline-none focus:ring-2 h-max focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-base leading-3 shadow-md rounded ${
                selectedItem === 'Space'
                  ? 'text-white bg-red-600'
                  : 'text-gray-600 border border-white bg-gray-50'
              }`}
            >
              Services
            </li>
            </NavLink>
            <NavLink to='/Portfolio'>
            <li
              onClick={() => handleSelect('Game')}
              className={`focus:outline-none focus:ring-2 h-max focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-base leading-3 shadow-md rounded ${
                selectedItem === 'Game'
                  ? 'text-white bg-red-600'
                  : 'text-gray-600 border border-white bg-gray-50'
              }`}
            >
              Portfolio
            </li>
            </NavLink>
            <NavLink to='/Why-us'>
            <li
              onClick={() => handleSelect('Utility')}
              className={`focus:outline-none focus:ring-2 h-max focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-base leading-3 shadow-md rounded ${
                selectedItem === 'Utility'
                  ? 'text-white bg-red-600'
                  : 'text-gray-600 border border-white bg-gray-50'
              }`}
            >
              Why Us
            </li>
            </NavLink>
            <NavLink to='/contact-us'>
            <li
              onClick={() => handleSelect('Cards')}
              className={`focus:outline-none focus:ring-2 h-max focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-base leading-3 shadow-md rounded ${
                selectedItem === 'Cards'
                  ? 'text-white bg-red-600'
                  : 'text-gray-600 border border-white bg-gray-50'
              }`}
            >
              Contact us
            </li>
            </NavLink>
          </ul>

          {/* <div className="flex space-x-5 justify-center items-center pl-2">
            <div className="relative cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 ">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                  stroke="#1F2937"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="animate-ping w-1.5 h-1.5 bg-indigo-700 rounded-full absolute -top-1 -right-1 m-auto duration-200"></div>
              <div className="w-1.5 h-1.5 bg-indigo-700 rounded-full absolute -top-1 -right-1 m-auto shadow-lg"></div>
            </div>

            <svg
              className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div> */}
        </nav>

        {/* for smaller devices */}
        <div className="block m-auto md:hidden">
          <div
            onClick={toggleList}
            className="cursor-pointer px-0 py-0 text-red-400  rounded flex justify-between items-center w-full"
          >
            
            {isListOpen ? <RxCross2  className="h-[30px] w-[30px]"/> : <MdOutlineMenu className="h-[30px] w-[30px]"/>}
            

          </div>
          <div className={`relative ${isListOpen ? 'block' : 'hidden'}`}>
            <ul
              id="list"
              className="font-normal z-40 right-[-26px]	 text-base leading-4 absolute top-7 w-[180px] rounded shadow-md"
            ><NavLink to="/About">
              <li
                onClick={() => handleSelect('Arts')}
                className="px-4 py-3 text-gray-600 bg-gray-50 border border-gray-50 focus:outline-none focus:bg-gray-100 hover:bg-gray-100 duration-100 cursor-pointer text-xs leading-3 font-normal"
              >
                About Us
              </li>
              </NavLink>
              <NavLink to="/Services">
              <li
                onClick={() => handleSelect('Space')}
                className="px-4 py-3 text-gray-600 bg-gray-50 border border-gray-50 focus:outline-none focus:bg-gray-100 hover:bg-gray-100 duration-100 cursor-pointer text-xs leading-3 font-normal"
              >
                Services
              </li>
              </NavLink>
              <NavLink to="/Portfolio">
              <li
                onClick={() => handleSelect('Game')}
                className="px-4 py-3 text-gray-600 bg-gray-50 border border-gray-50 focus:outline-none focus:bg-gray-100 hover:bg-gray-100 duration-100 cursor-pointer text-xs leading-3 font-normal"
              >
                Portfolio
              </li>
              </NavLink>
              <NavLink to="/Why-us">
              <li
                onClick={() => handleSelect('Utility')}
                className="px-4 py-3 text-gray-600 bg-gray-50 border border-gray-50 focus:outline-none focus:bg-gray-100 hover:bg-gray-100 duration-100 cursor-pointer text-xs leading-3 font-normal"
              >
                Why Us
              </li>
              </NavLink>
              <NavLink to="/contact-us">
              <li
                onClick={() => handleSelect('Cards')}
                className="px-4 py-3 text-gray-600 bg-gray-50 border border-gray-50 focus:outline-none focus:bg-gray-100 hover:bg-gray-100 duration-100 cursor-pointer text-xs leading-3 font-normal"
              >
                Contact Us
                
              </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
     
    </div>
    <Routes>
    <Route  path='/' Component={Home}/>
    <Route  path='/About' Component={AboutPage}/>
    <Route  path='/Services' Component={ServicePage}/>
    <Route  path='/Portfolio' Component={PortfolioPage}/>
    <Route  path='/Why-us' Component={WhyPage}/>
    <Route  path='/contact-us' Component={ContactUs}/>
    <Route  path='/Privacy' Component={Privacy}/>
    <Route  path='/Form' Component={ReqForm}/>
    <Route  path='/thank-you' Component={thankyou}/>

    </Routes>
    </>
  );
};

export default NavBar;
