import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { IoHome } from "react-icons/io5";
import { FaLaptopCode } from "react-icons/fa";
import { FaPhotoVideo } from "react-icons/fa";
import { MdDesignServices } from "react-icons/md";

// Import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Hero = () => {
  const mainSwiperRef = useRef(null); // Reference to the main swiper
  const [activeThumb, setActiveThumb] = useState(0); // State to track active thumbnail index

  const handleThumbClick = (index) => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      mainSwiperRef.current.swiper.slideTo(index);
      setActiveThumb(index); // Update active thumbnail index
    }
  };

  const slides = [
    {
      id: 1,
      caption:"Where Innovation Meets Functionality",
      heading: 'From Concept to Completion',
      cap1:"Under ",
      cap2:"One ",
      cap3:"Roof",
      text: 'Partner with our experts for personalized consultation and implementation of technology solutions tailored to meet your unique business challenges.',
      buttonLabel: 'Explore',
      imageSrc: '../images/b1.svg',
      altText: 'Software',
    },
    {
      id: 2,
      heading: 'Next-Generation Software Solutions Tailored to Your Needs',
      text: 'We offer cutting-edge software solutions meticulously crafted to align with your business objectives, ensuring unparalleled efficiency and innovation.',
      buttonLabel: 'Explore',
      imageSrc: '../images/b2.svg',
      altText: 'Programming & Tech',
    },
    {
      id: 3,
      heading: 'Customizable Video and Animation Suites for Every Need',
      text: 'From explainer videos to 3D animations, our customizable suites cater to diverse marketing, training, and storytelling requirements.',
      buttonLabel: 'Explore',
      imageSrc: '../images/b3.svg',
      altText: 'Video & Animation',
    },
    {
      id: 4,
      heading: 'Graphic Design Mastery for Print and Digital Media',
      text: 'Develop creative brand strategies that set you apart in the market, leveraging design to communicate your unique value proposition.',
      buttonLabel: 'Explore',
      imageSrc: '../images/b4.svg',
      altText: 'Graphics',
    },
  ];

  const Thumbnails = [
    {
      id: 0,
      icon: <IoHome className='m-auto h-[25px] w-[25px]' />,
      label: 'Home',
    },
    {
      id: 1,
      icon: <FaLaptopCode className='m-auto  h-[25px] w-[25px]' />,
      label: 'Program & Tech',
    },
    {
      id: 2,
      icon: <FaPhotoVideo className='m-auto h-[25px] w-[25px]' />,
      label: 'Video & Animation',
    },
    {
      id: 3,
      icon: <MdDesignServices className='m-auto h-[25px] w-[25px]' />,
      label: 'Graphics & Branding',
    },
  ];

  return (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#000',
          '--swiper-pagination-color': '#000',
        }}
        spaceBetween={10}
        navigation={true}
        modules={[Navigation, Thumbs]}
        className="mySwiper2"
        ref={mainSwiperRef}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="lg:flex w-[90%] sm:block m-auto">
              <div className="lg:w-1/2  sm:w-full m-auto p-8 max-sm:px-1 max-sm:p-4">
              <div className='flex'>
              <hr className='h-[4px] bg-red-500 my-auto mr-2 w-[30px]'></hr>
              <span className='max-sm:text-[12px]'>{slide.caption}</span>
              </div>
                <h2 className="text-3xl max-sm:text-xl font-bold  mt-4 mb-4 max-sm:mt-2 max-sm:mb-2">{slide.heading}</h2>
                <h2 className="text-4xl font-bold uppercase mb-4 max-sm:mb-2">
                <span className='text-[#00d648]'>{slide.cap1}</span>
                <span className='text-[#ffca00]'>{slide.cap2}</span>
                <span className='text-[#ec4f5c]'>{slide.cap3}</span> </h2>
                <p className="text-gray-600 truncate	 mb-4">{slide.text}</p>
                <img
                  src={slide.imageSrc}
                  className="w-full h-[350px] block sm:hidden max-sm:h-[250px] object-contain"
                  alt={slide.altText}
                />
                <Link to="/Services" className="bg-red-500 max-sm:mt-4 hover:bg-red-700 text-white font-bold py-2 px-8 rounded">
                  {slide.buttonLabel}
                </Link>
                <Link to="/contact-us" className="bg-black ml-4 max-sm:mt-4 hover:bg-green-700 text-white font-bold py-2 px-8 rounded">
                  Contact
                </Link>
              </div>
              <div className="lg:w-1/2 hidden sm:block  sm:w-full relative">
                <img
                  src={slide.imageSrc}
                  className="w-full h-[350px] max-sm:h-[150px] object-contain"
                  alt={slide.altText}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className='w-[90%] block max-sm:hidden m-auto'>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          className="mySwiper desktop"
        >
          {Thumbnails.map((thumb, index) => (
  <SwiperSlide key={thumb.id} onClick={() => handleThumbClick(thumb.id)}>
    <div className={`text-center  m-auto bg-opacity-25 bg-white backdrop-filter backdrop-blur-lg shadow-lg p-4 ${index === activeThumb ? 'border-red-500 border-b-4' : ''}`}>
     <span className='text-red-500'> {thumb.icon}</span>
      <p className="md:text-xl mt-4 sm:text-[22px] text-[26px] text-center thumb_text">{thumb.label}</p>
    </div>
  </SwiperSlide>
))}
        </Swiper>
        </div>
        <div className='hidden max-sm:block'>
        <Swiper
          spaceBetween={10}
          slidesPerView={2.5}
          freeMode={true}
          watchSlidesProgress={true}
          className="mySwiper mobile"
        >
          {Thumbnails.map((thumb, index) => (
  <SwiperSlide key={thumb.id} onClick={() => handleThumbClick(thumb.id)}>
    <div className={`text-center  m-auto bg-opacity-25 bg-white backdrop-filter backdrop-blur-lg shadow-lg p-4 ${index === activeThumb ? 'border-red-500 border-b-4' : ''}`}>
     <span className='text-red-500'> {thumb.icon}</span>
      <p className="text-[14px] mt-4  text-center thumb_text">{thumb.label}</p>
    </div>
  </SwiperSlide>
))}
        </Swiper>
      </div>
    </>
  );
};

export default Hero;