import React, { useRef, useState, useEffect } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ReactPlayer from 'react-player'

import 'swiper/css';
import 'swiper/css/navigation';

const HomePortfolio= () =>{
  const swiperRef = useRef();
  const swiperRef2 = useRef();
  const swiperRef3 = useRef();
  const swiperRef4 = useRef();



    return (
        <>
       
             <div className="flex flex-col items-center justify-start max-w-[100%] mt-32 max-sm:mt-5 md:mt-10 mx-auto md:px-0 w-full">
          <div className="flex flex-col gap-[0px] md:gap-[0px] items-center justify-start w-full">
            <div className="flex flex-col gap-2.5 items-center  w-[100%] md:w-full">
            <div className="md:w-[60%] sm:w-[100%] m-auto">
                    <h2 className='my-4 font-bold text-center text-3xl sm:text-4xl'>Portfolio</h2>
             
               <p className="text-gray-700 text-center">
                <>
                For your Programming & Tech, Video & Animation,and Graphics & Design, consider Core Spectators. 
                We specialize in delivering unique and superior solutions across these domains.!{" "}
                </>
              </p>
              </div>
              </div>
                <div className="Pcont pt-10 w-[80%]">
                    <div className="Pitem rond shadow-bs9">
                    <div className='Pmain'>
                   
                    <div className="Sinside">
        {/* Programing */}
        <div style={{position:'relative'}}>
      <Swiper
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide>     
       <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/web1.png?v=1719238690" style={{height:"300px", width:"100%"}}/> 
       </SwiperSlide>
       <SwiperSlide>     
       <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/web3.png?v=1719238691" style={{height:"300px", width:"100%"}}/> 
       </SwiperSlide><SwiperSlide>     
       <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/web2.png?v=1719238692" style={{height:"300px", width:"100%"}}/> 
       </SwiperSlide><SwiperSlide>     
       <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/web4.png?v=1719238692" style={{height:"300px", width:"100%"}}/> 
       </SwiperSlide>
       <SwiperSlide>     
       <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/web5.png?v=1719238693" style={{height:"300px", width:"100%"}}/> 
       </SwiperSlide><SwiperSlide>     
       <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/web6.png?v=1719238692" style={{height:"300px", width:"100%"}}/> 
       </SwiperSlide>
      
       {/* <SwiperSlide>
       <video style={{height:"300px", width:"100%"}} controls >
     <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4" type="video/mp4"/>
    </video>
       </SwiperSlide> */}
        
      </Swiper>
      
        <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}}  onClick={() => swiperRef.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}} onClick={() => swiperRef.current?.slideNext()}></button>
      </div>
     
        {/* Programing */}
        <a href="/Pages/TechS">
        <p className="text-left sm:text-[25.81px] mt-6 mb-6 md:text-[27.81px] text-[29.81px] text-center text-gray-900 font-lato font-semibold">
        Websites
        </p>
        </a>
      </div>
 
      </div>
                    </div>
                    <div className="Pitem rond shadow-bs9">
                  
                    <div className="Sinside">
      {/* video slide */}
      <div style={{position:'relative'}}>
      <Swiper
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef2.current = swiper;
        }}
      >
        <SwiperSlide>
        <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/White_Green_Mobile_Mockup_Photo_Collage_Beauty_Facebook_Post_2.png?v=1719238684" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide>
        <SwiperSlide>
        <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/White_Green_Mobile_Mockup_Photo_Collage_Beauty_Facebook_Post_1.png?v=1719238683" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/White_Green_Mobile_Mockup_Photo_Collage_Beauty_Facebook_Post.png?v=1719238682" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/White_Green_Mobile_Mockup_Photo_Collage_Beauty_Facebook_Post_4.png?v=1719238682" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img  className='rounded-md	' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/White_Green_Mobile_Mockup_Photo_Collage_Beauty_Facebook_Post_3.png?v=1719238682" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide>
        {/* <SwiperSlide><video style={{height:"300px", width:"100%"}} controls >
      <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4" type="video/mp4"/>
     </video>
   
        </SwiperSlide> */}
           
      </Swiper>
        <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}}  onClick={() => swiperRef2.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}} onClick={() => swiperRef2.current?.slideNext()}></button>
      </div>
      {/* video slides */}
      <a href="/Pages/VideoS">
        <p className="text-left sm:text-[25.81px] mt-6 mb-6 md:text-[27.81px] text-[29.81px] text-center text-gray-900 font-lato font-semibold">
        Apps
        </p></a>  
      </div>
    
                        </div>
                </div>
                {/* Second Row */}
                <div className="Pcont md:mt-[-6%]">
                    <div className="Pitem rond shadow-bs9 ">
                    <div className='Pmain'>
                   
                    <div className="Sinside">
 {/* Writing slide */}
 <div style={{position:'relative'}}>
      <Swiper
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef3.current = swiper;
        }}
      >
        
        <SwiperSlide style={{height:"300px", width:"100%"}}>
        <ReactPlayer className="w-full retp rounded-md"  controls url='https://cdn.shopify.com/videos/c/o/v/c30537d57c6141d6a09a6a4195af05e2.mp4' />
    </SwiperSlide>
    <SwiperSlide style={{height:"300px", width:"100%"}}>
        <ReactPlayer className="w-full retp rounded-md"  controls url='https://cdn.shopify.com/videos/c/o/v/df97e05fc81a4745a8a117aaf5968b7d.mp4' />
    </SwiperSlide>
    <SwiperSlide style={{height:"300px", width:"100%"}}>
        <ReactPlayer className="w-full retp rounded-md"  controls url='https://cdn.shopify.com/videos/c/o/v/a3deb8ada34a4beab9cf5d95e58bc20d.mp4' />
    </SwiperSlide>
    <SwiperSlide style={{height:"300px", width:"100%"}}>
        <ReactPlayer className="w-full retp rounded-md"  controls url='https://cdn.shopify.com/videos/c/o/v/658f511abcbf45d6b0ebc7ee73d42518.mp4' />
    </SwiperSlide>
    <SwiperSlide style={{height:"300px", width:"100%"}}>
        <ReactPlayer className="w-full retp rounded-md"  controls url='https://cdn.shopify.com/videos/c/o/v/3ba883a3695543a98ed92643dd80ffe8.mp4' />
    </SwiperSlide>
    <SwiperSlide style={{height:"300px", width:"100%"}}>
        <ReactPlayer className="w-full retp rounded-md"  controls url='https://cdn.shopify.com/videos/c/o/v/a5f9588521024da0a0329ec7c77d781d.mp4' />
    </SwiperSlide>     
   
      </Swiper>
        <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}}  onClick={() => swiperRef3.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}} onClick={() => swiperRef3.current?.slideNext()}></button>
      </div>
      <a href="/Pages/WritingS">
        <p className="text-left sm:text-[25.81px] mt-6 mb-6 md:text-[27.81px] text-[29.81px] text-center text-gray-900 font-lato font-semibold">
        Video And Animation
        </p></a>
      {/* Writing slides */}
      </div>

      </div>
                    </div>
                    <div className="Pitem rond shadow-bs9">
                    
                    <div className="Sinside">
                    {/* Graphics */}
<div style={{position:'relative'}}>
      <Swiper
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef4.current = swiper;
        }}
      >
        <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/1.png?v=1719288343" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide>
        <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/2.png?v=1719288345" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/3.png?v=1719288346" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/4.png?v=1719288342" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/5.png?v=1719288344" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide> <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/6.png?v=1719288349" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide>
        <SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/7.png?v=1719288349" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide><SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/8.png?v=1719288337" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide><SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/9.png?v=1719288347" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide><SwiperSlide>
        <img className='rounded-md' src="https://cdn.shopify.com/s/files/1/0110/5320/3522/files/10.png?v=1719288347" style={{height:"300px", width:"100%"}}/>
        </SwiperSlide>
       
        
      </Swiper>
        <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}}  onClick={() => swiperRef4.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-17px", top:"55%", background: "#000", width: "36px", height: "35.997px"}} onClick={() => swiperRef4.current?.slideNext()}></button>
      </div>
      <a href="/Pages/GraphicS">
      <p className="text-left sm:text-[25.81px] mt-6 mb-6 md:text-[27.81px] text-[29.81px] text-center text-gray-900 font-lato font-semibold">
        Graphics And Design
        </p>
        </a>
                    {/* Graphics */}
                    
      </div>
     
                        </div>
                </div>
              </div>
              </div>
        </>
        )
    };
    export default HomePortfolio;