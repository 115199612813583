import React from "react";

const ContactUs=()=>{
    return(<>
           <div className="bg-white">
      <section className="text-center py-12 px-4">
        <h2 className="text-2xl font-bold">Get In Touch</h2>
        <p className="mt-4 text-gray-700 max-w-2xl mx-auto">
          We are here to help you. Reach out to us via any of the following methods.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8 animate-fadeIn">
          <ContactMethod title="Call Us" content="+92 333 5136060" />
          <ContactMethod title="Email Us" content="contact@corespectators.com" />
          <ContactMethod title="Visit Us" content="Islamabad, Pakistan" />
        </div>
      </section>
      <section className="bg-gray-100 py-12 px-4">
      <div className="flex max-sm:blockx">
        <div className="w-[50%] max-sm:w-full">
            <img src="../images/cont.svg"/>
        </div>
        <div className="w-[50%] max-sm:w-full">
        <h2 className="text-2xl font-bold text-center">Send Us A Message</h2>
        <form className="max-w-2xl mx-auto mt-8 space-y-8">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-bold">Name</label>
            <input type="text" id="name" className="w-full mt-2 p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 transition" />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-bold">Email</label>
            <input type="email" id="email" className="w-full mt-2 p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 transition" />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-700 font-bold">Message</label>
            <textarea id="message" rows="5" className="w-full mt-2 p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 transition"></textarea>
          </div>
          <button type="submit" className="w-full bg-red-700 text-white py-3 rounded-lg shadow-lg hover:bg-red-600 transition-colors">Send Message</button>
        </form>
        </div>

      </div>
       
      </section>
      <section className="text-center py-12 px-4">
        <h2 className="text-2xl font-bold">Frequently Asked Questions</h2>
        <div className="mt-8">
          <FAQ question="What are your operating hours?" answer="We operate from 9 AM to 5 PM, Monday to Friday." />
          <FAQ question="How can I book an appointment?" answer="You can book an appointment through our website or by calling our office." />
          <FAQ question="Do you offer telemedicine services?" answer="Yes, we offer telemedicine consultations." />
        </div>
      </section>
    </div>

    </>)
}
function ContactMethod({ title, content }) {
    return (
      <div className="p-4 shadow-lg rounded-lg bg-red-500 hover:bg-red-800 transition-colors">
        <h3 className="text-xl text-white font-bold">{title}</h3>
        <p className="text-white mt-2">{content}</p>
      </div>
    );
  }
  
  function FAQ({ question, answer }) {
    return (
      <div className="p-4 border rounded-lg shadow-md transition transform hover:scale-100 scale-90">
        <h3 className="text-xl font-bold">{question}</h3>
        <p className="mt-2 text-gray-700">{answer}</p>
      </div>
    );
  }
export default ContactUs;