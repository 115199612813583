import React from 'react';

const AboutSection = () => {
  return (
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">About us</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
            About <span className="text-red-600">Our Company</span>
          </h2>
          <p className="text-gray-700">
          From crafting seamless websites and dynamic apps to igniting brands with captivating graphics, 
          compelling videos, and strategic ecommerce solutions, we redefine digital excellence. 
          Our mission? To empower your vision with unparalleled creativity and technical prowess, 
          transforming ideas into impactful realities. Together, let’s pioneer the future of digital experiences."
          </p>
        </div>
      </div>
      <div className="sm:w-1/2 p-10 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/HomeAbout.svg" alt="About Us Image" />
        </div>
      </div>
      
    </div>
  );
};

export default AboutSection;
