import React from "react";

const WhyPage=()=>{
    return(<>
        <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">Why us</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
          Why Choose  <span className="text-red-600">Core Spectators?</span>
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp1.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          We offer a seasoned team comprising editors, graphic designers, developers, programmers, animators, and content writers. Our approach is rooted in conceptual thinking, seeking optimal solutions with an open-minded perspective to effectively communicate your brand's narrative.</p>
          <p className="text-gray-700">
          Consider the profound impact of integrating our team members into your operations, where their collective effort is significantly more efficient than working in isolation. Each member contributes unique insights, ensuring comprehensive coverage and leveraging individual strengths to uphold elevated standards of excellence. This collaborative environment fosters mutual accountability, delivering outcomes that surpass expectations.</p>
        </div>
      </div>
      <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp1.svg" alt="About Us Image" />
        </div>
      </div>
      
    </div>
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp2.svg" alt="About Us Image" />
        </div>
      </div>
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">Satisfaction</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
       Commitment to 
 <span className="text-red-600"> Client Success </span>
         
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp2.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          Our philosophy revolves around prioritizing the client's needs and objectives. Our foremost goal is to deeply comprehend the essence of our customer's brand and their business aspirations.  </p>
          <p className="mb-4 text-gray-700">By placing our clients at the center of our approach, we ensure that every solution and strategy we develop is meticulously aligned with their unique vision and goals. This client-centric focus not only enhances our ability to deliver tailored services but also fosters long-term partnerships built on trust and mutual success.</p>
        </div>
      </div>  
    </div>
            {/* second */}
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
        <span className="text-black border-b-2 border-red-600 uppercase">Integrity</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
          
          Commitment to <span className="text-red-600">Integrity</span>
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp3.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          At our core, we uphold a steadfast commitment to conducting business with unwavering honesty and integrity. Our philosophy is rooted in the belief that trust is paramount in every client relationship. We prioritize transparency, ensuring that our recommendations and services align precisely with your genuine needs and objectives.
         </p>
          <p className="text-gray-700">
          Our dedication to integrity means you can rely on us to always act in your best interests, delivering measurable value and fostering a partnership built on trust and mutual respect.
</p>
        </div>
      </div>
      <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp3.svg" alt="About Us Image" />
        </div>
      </div>
      
    </div>
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp4.svg" alt="About Us Image" />
        </div>
      </div>
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">Flexibility</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
          Embracing 
 <span className="text-red-600">Flexibility </span>
         
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp4.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          Our approach is centered around providing a personalized solution that addresses your unique challenges and goals. By understanding the intricacies of your business and objectives, we can offer a customized array of services that maximizes efficiency and effectiveness.
          </p> 
          <p className="mb-4 text-gray-700">
          At our agency, you have the freedom to choose the specific services that align with your current priorities and budget. This flexibility ensures that you receive optimal value from every investment, without paying for unnecessary extras. 
          </p> 
        </div>
      </div>
      
      
    </div>
    {/* 3rd */}
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
        <span className="text-black border-b-2 border-red-600 uppercase">Transparency</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
               Emphasizing 
<span className="text-red-600">Transparency</span>
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp5.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          At our agency, transparency is fundamental to our approach in fostering effective client-agency relationships. We prioritize clear and open communication through scheduled calls, regular check-ins, and the use of transparent processes and tools. These practices ensure that you are informed about every aspect of our collaboration, from project milestones to strategic decisions.</p>
        
        </div>
      </div>
      <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp5.svg" alt="About Us Image" />
        </div>
      </div>
      
    </div>
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp6.svg" alt="About Us Image" />
        </div>
      </div>
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">Delivery</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
          Commitment to  
 <span className="text-red-600">Timely Delivery </span>
         
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/wp6.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          We prioritize understanding your business goals and brand before committing to short-term or long-term strategies, avoiding unrealistic promises. Our approach recognizes the importance of time as a valuable asset in business, ensuring that we set and maintain realistic deadlines.</p> 
        </div>
      </div>
      
      
    </div>
    </>)
}
export default WhyPage;