import React, { useState } from 'react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReqForm = () => {
    const [currentSection, setCurrentSection] = useState(0);
    // State for popup message
    const [popupMessage, setPopupMessage] = useState('');
    const initialFormData = {
        client_name: '',
        phone: '',
        email: '',
        start_date: '',
        completion_date: '',
        project_description: '',
        target_audience: '',
        competitors: '',
        seo: 'Yes', 
        platform: 'Website Development', 
        pages: [],
        design_preferences: '',
        responsive: 'Yes',
    };
    const [formData, setFormData] = useState(initialFormData);

    
    const handleChange = (event) => {
        
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log({name: name,value: value });
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setFormData(prevData => {
            const pages = checked 
                ? [...prevData.pages, value] 
                : prevData.pages.filter(page => page !== value);
            return { ...prevData, pages };
        });
    };
    const sections = [
        {
            title: "Basic Information",
            content: (
                <>
                    <label className="font-semibold">Client/Company Name:</label>
                    <input 
                       type="text" 
                        name="client_name"
                        id='client_name' 
                        value={formData.client_name}
                        onChange={handleChange} 
                        placeholder="Enter client/company name" 
                        required 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />

                    <label className="font-semibold">Phone Number:</label>
                    <input 
                       type="tel" 
                        name="phone" 
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Enter phone number" 
                        required 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />

                    <label className="font-semibold">Email Address:</label>
                    <input 
                        type="email" 
                        name="email" 
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email address" 
                        required 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />

                    <label className="font-semibold">Project Start Date:</label>
                    <input 
                       type="date" 
                        name="start_date" 
                        value={formData.start_date}
                        onChange={handleChange}
                        required 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />

                    <label className="font-semibold">Expected Completion Date:</label>
                    <input 
                       type="date" 
                        name="completion_date" 
                        value={formData.completion_date}
                        onChange={handleChange}
                        required 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />
                </>
            )
        },
        {
            title: "Project Overview",
            content: (
                <>
                    <label className="font-semibold">Brief Description of the Project:</label>
                    <textarea 
                        name="project_description" 
                        value={formData.project_description}
                        onChange={handleChange} 
                        placeholder="Describe the project" 
                        rows="4" 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"></textarea>

                    <label className="font-semibold">Target Audience:</label>
                    <input 
                       type="text" 
                        name="target_audience" 
                        value={formData.target_audience}
                        onChange={handleChange}
                        placeholder="Enter target audience" 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />

                    <label className="font-semibold">Competitor Websites (if any):</label>
                    <input 
                        type="url" 
                        name="competitors" 
                        value={formData.competitors}
                        onChange={handleChange} 
                        placeholder="Enter competitor websites" 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500" 
                    />
                    <label className="font-semibold">SEO Optimization:</label>
                    <select 
                        name="seo" 
                        value={formData.seo}
                        onChange={handleChange}
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500">
                        <option>Yes</option>
                        <option>No</option>
                        <option>Unsure</option>
                    </select>
                </>
            )
        },
        {
            title: "Technical Requirements",
            content: (
                <>
                    <label className="font-semibold">Preferred Platform:</label>
                    <select 
                       name="platform" 
                        value={formData.platform}
                        onChange={handleChange}
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500">
                        <option>Website Development</option>
                        <option>Mobile App Development</option>
                        <option>Custom Development</option>
                        <option>Marketing</option>
                        <option>Graphic Designing</option>
                    </select>

                    <label className="font-semibold">Required Pages:</label>
                    <div className="mb-3 mt-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 w-full text-sm font-medium text-gray-900 ">
    {['Home', 'About', 'Services', 'Blog', 'Contact', 'Portfolio'].map(page => (
        <div key={page} className="flex items-center p-3 border border-gray-200 rounded-lg">
            <label className="inline-flex items-center">
                <input 
                    type="checkbox" 
                    name="pages" 
                    checked={formData.pages.includes(page)}
                    onChange={handleCheckboxChange}
                    value={page} 
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" 
                />
                <span className="ms-2 text-sm font-medium text-gray-900">{page}</span>
            </label>
        </div>
    ))}
</div>

                    </div>

                    <label className="font-semibold">Design & Aesthetic Preferences:</label>
                    <textarea 
                         name="design_preferences" 
                        value={formData.design_preferences}
                        onChange={handleChange} 
                        placeholder="Describe design preferences" 
                        rows="2" 
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"></textarea>

                    <label className="font-semibold">Is the site responsive/mobile-friendly?</label>
                    <select 
                        name="responsive" 
                        value={formData.responsive}
                        onChange={handleChange}
                        className="w-full p-3 mb-3 border border-gray-300 rounded-md bg-white text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500">
                        <option>Yes</option>
                        <option>No</option>
                        <option>Unsure</option>
                    </select>
                </>
            )
        },
    ];
   
    const handleNext = () => {
        if (currentSection < sections.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handleBack = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(
           formData
       );
        try {
            const response = await axios.post('https://corespectators.com/api/submit_form.php', formData);
            if (response.data.success) {
                // setPopupMessage("Form submitted successfully!");
                console.log("Form submitted successfully");
                // Optionally reset the form or show a success message
                toast.success("Form submitted successfully!");
                setFormData(initialFormData);
                setCurrentSection(0);
                // setTimeout(() => {
                //     setPopupMessage('');
                // }, 5000);
            } else {
                console.error("Error submitting form:", response.data.error);
                // setPopupMessage("Try submitted successfully!");
                toast.error("Error submitting form. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Error submitting form. Please try again.");
        }
    };
    
    // Calculate progress percentage
    const progressPercentage = ((currentSection + 1) / sections.length) * 100;

    return (
        <div className="block md:flex w-full bg-transparent">
            {/* Left Image Section */}
            <div className="w-full hidden md:block md:w-1/2">
                <img src="../images/form.svg" alt="Form" className="w-full h-full object-contain" />
            </div>

            {/* Right Form Section */}
            <div className="w-full md:w-1/2 flex items-center justify-center p-5">
                <div className="bg-[#c6c6c640] p-6 rounded-lg w-full max-w-lg">
                {popupMessage && (
                        <div className="bg-green-500 text-white text-center p-2 rounded mb-4">
                            {popupMessage}
                        </div>
                    )}
                    {/* Progress Bar */}
                    <div className="mb-4">
                        <div className="bg-gray-600 rounded-full h-2">
                            <div className="bg-red-500 h-2 rounded-full" style={{ width: `${progressPercentage}%` }}></div>
                        </div>
                        <p className="text-black text-sm text-right">{currentSection + 1} / {sections.length}</p>
                    </div>

                    <h1 className="text-red-500 text-2xl mb-4">{sections[currentSection].title}</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            {sections[currentSection].content}
                        </div>
                        <div className="flex justify-between mt-4">
                            <button type="button" onClick={handleBack} className="bg-gray-600 text-white py-2 px-4 flex items-center gap-1 rounded hover:bg-gray-500" disabled={currentSection === 0}>
                                <FaChevronLeft /> Back
                            </button>
                            {currentSection < sections.length - 1 ? (
                                <button type="button" onClick={handleNext} className="bg-red-500 flex items-center gap-1 text-white py-2 px-4 rounded hover:bg-red-600">
                                    Next <FaChevronRight />
                                </button>
                            ) : (
                                <button type="submit" className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
            <div className="w-full block md:hidden md:w-1/2">
                <img src="../images/form.svg" alt="Form" className="w-full h-full object-contain" />
            </div>
        </div>
    );
};

export default ReqForm;
