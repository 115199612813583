import React from 'react';
import { Link  } from 'react-router-dom';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const Services = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"  // Optional: smooth scroll animation
    });
  };
  const servicesColumn1 = [
    'Full Website Creation',
    'Web design',
    'Customization',
    'Landing Page',
    'Tech Support',
  ];

  const servicesColumn2 = [
    'Bug Fixes',
    'Email Template',
    'App Creation',
    'Convert Site to App',
    'ChatBot',
  ];

  const VideoColumn1 = [
    'Video Editing',
    'Whiteboard Animation',
    '2D Animated Video',
    '3D Animation Video',
    'Logo Animation',
  ];
  
  const VideoColumn2 = [
    'Character Animation',
    '3D Product Animation',
    'Video Ads',
    'Isometric Explainers',
    'App & Website Report',
  ];
  const GraphicsColumn1 = [
    'Logo Design',
    'UI UX Design',
    'Packaging & Label',
    'Menu Design',
    'Banner Design',
  ];
  
  const GraphicsColumn2 = [
    'Flyer Design',
    'Brochure Design',
    'Business Card',
    'Poster Design',
    'Arts & Illustration',
  ];
  const renderServiceList = (services) => (
    <ul className="space-y-2">
      {services.map((service, index) => (
        <li key={index} className="flex items-start">
          <svg
            className="w-5 h-5 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="#ef4444"
          >
            <path
              fillRule="evenodd"
              d="M17 4a1 1 0 011 1v10a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1h14zm-1 2H4v8h12V6zm-4 4a1 1 0 00-2 0v3a1 1 0 102 0v-3zm-4 0a1 1 0 00-2 0v3a1 1 0 102 0v-3z"
              clipRule="evenodd"
            />
          </svg>
       <span className='max-sm:text-[12px]'>{service}</span>
        </li>
      ))}
    </ul>
  );
  return (
    <>
       <div className="md:w-[60%] sm:w-[100%] m-auto">
                    <h2 className='my-4 font-bold text-center text-3xl sm:text-4xl'>Services</h2>
             
               <p className="text-gray-700 text-center">
                <>
                For your Programming & Tech, Video & Animation,and Graphics & Design, consider Core Spectators. 
                We specialize in delivering unique and superior solutions across these domains.!{" "}
                </>
              </p>
              </div>
      {/* Programming & Tech Section */}
      <div className="px-2 py-10 max-sm:py-2 w-full flex justify-center">
        <div className="bg-white w-full lg:mx-8 lg:flex lg:max-w-5xl shadow-lg rounded-lg">
          <div className="lg:w-[45%] sm:w-[full]">
            <div
              className="lg:scale-100 h-80 bg-contain lg:h-full "
            ><img className='h-full' src='./images/p1.svg'/></div>
          </div>
          <div className="py-12 lg:w-[55%] sm:w-[full] px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 ">
            <h2 className="text-3xl text-gray-800 text-center font-bold">
              Programming  
              <span className="text-red-600"> & </span>
              Tech
            </h2>
            <p className="mt-4 text-[12px] text-center text-gray-600">
              Your comprehensive destination for all programming solutions. Find tailored services to meet your software needs efficiently and effectively.
            </p>
            <div className="flex justify-start">
              <div className="max-w-md text-left m-4">
                {renderServiceList(servicesColumn1)}
              </div>
              <div className="max-w-md text-left m-4">
                {renderServiceList(servicesColumn2)}
              </div>
            </div>
            <div className="mt-8 text-center">
              <Link onClick={handleScrollToTop} to="/Form" className="bg-red-500 text-red-100 px-5 py-3 font-semibold rounded">
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Video & Animation Section */}
      <div className="px-2 py-10 max-sm:py-2 w-full lg:flex sm:block justify-center">
        <div className="bg-white lg:mx-8 w-full lg:flex lg:max-w-5xl shadow-lg rounded-lg">
        <div className="lg:w-[45%] hidden max-sm:block sm:w-[full]">
          <div
              className="lg:scale-100 h-80 bg-contain lg:h-full "
            ><img className='h-full w-full' src='./images/p2.svg'/></div>
          </div>
          <div className="py-12 lg:w-[55%] sm:w-[full] px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 ">
            <h2 className="text-3xl text-gray-800 text-center font-bold">
              Video  
              <span className="text-red-600"> & </span>
              Animation
            </h2>
            <p className="mt-4 text-[12px] text-center text-gray-600">
              Explore our video and animation services tailored to meet your needs. From editing to advanced 3D animations, we've got you covered.
            </p>
            <div className="flex justify-start">
              <div className="max-w-md  m-4">
                {renderServiceList(VideoColumn1)}
              </div>
              <div className="max-w-md m-4">
                {renderServiceList(VideoColumn2)}
              </div>
            </div>
            <div className="mt-8 text-center">
              <Link onClick={handleScrollToTop} to="/Form"  className="bg-red-500 text-red-100 px-5 py-3 font-semibold rounded">
              Learn more
              </Link>
            </div>
          </div>
          <div className="lg:w-[45%] block max-sm:hidden sm:w-[full]">
          <div
              className="lg:scale-100 h-80 bg-contain lg:h-full "
            ><img className='h-full' src='./images/p2.svg'/></div>
          </div>
        </div>
      </div>
      {/* Graphic & Design Section */}
      <div className="px-2 py-10 max-sm:py-2 w-full lg:flex sm:block justify-center">
        <div className="bg-white lg:mx-8 w-full lg:flex lg:max-w-5xl shadow-lg rounded-lg">
        <div className="lg:w-[45%] sm:w-[full]">
        <div
              className="lg:scale-100 h-80 bg-contain lg:h-full "
            ><img className='h-full w-full' src='./images/p3.svg'/></div>
          </div>
          <div className="py-12 lg:w-[55%] sm:w-[full] px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 ">
            <h2 className="text-3xl text-gray-800 text-center font-bold">
              Graphics  
              <span className="text-red-600"> & </span>
              Branding
            </h2>
            <p className="mt-4 text-[12px] text-center text-gray-600">
            Graphics and design involve the art of visual communication. These services harness the power of illustration, photography, and typography to creatively address any visual communication needs.
            </p>
            <div className="flex justify-start">
              <div className="max-w-md  m-4">
                {renderServiceList(GraphicsColumn1)}
              </div>
              <div className="max-w-md m-4">
                {renderServiceList(GraphicsColumn2)}
              </div>
            </div>
            <div className="mt-8 text-center">
              <Link onClick={handleScrollToTop} to="/Form"  className="bg-red-500 text-red-100 px-5 py-3 font-semibold rounded">
              Learn more
              </Link>
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default Services;
