import React from "react";

const AboutPage=()=>{
    return(<>
        <div className="sm:flex items-center m-auto max-w-screen-xl">
       
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">About us</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
          Premier Digital  <span className="text-red-600">Services Agency</span>
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/ab1.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          Our mission is to empower businesses by enhancing their digital presence, allowing them to focus on their core operations. Whether you're a professional in need of a website or a large corporation aiming to establish a prominent online footprint, 
          Core Spectators is dedicated to delivering tailored solutions that foster growth and visibility."
          </p>
          <p className="text-gray-700">
          Since our inception in 2019, we have supported numerous enterprises, both small and large, in building and expanding their online reach. Drawing from extensive experience in the digital media industry, we possess the expertise to strategically elevate your business to new heights."
          </p>
        </div>
      </div>
      <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/ab1.svg" alt="About Us Image" />
        </div>
      </div>
      
    </div>
    <div className="sm:flex items-center m-auto max-w-screen-xl">
    <div className="sm:w-1/2 block max-sm:hidden p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/ab2.svg" alt="About Us Image" />
        </div>
      </div>
    <div className="sm:w-1/2 ml-8 max-sm:ml-0 p-5 max-sm:pb-0">
        <div className="text">
          <span className="text-black border-b-2 border-red-600 uppercase">Crafted by Creative Intellect</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
          Core Spectators Thrives as a   
 <span className="text-red-600"> Dynamic, Skilled, </span>
            and
 <span className="text-red-600"> Committed </span>
  Digital Agency.
          </h2>
          <div className="sm:w-1/2 hidden max-sm:block p-10 py-5 max-sm:p-0">
        <div className="image object-center text-center">
          <img src="../images/ab2.svg" alt="About Us Image" />
        </div>
      </div>
          <p className="mb-4 text-gray-700">
          At Core Spectators, we cultivate an environment that empowers our team to deliver exceptional work, fosters innovation, and nurtures passion and ingenuity. Committed to surpassing boundaries, we strive to achieve ambitious objectives in every project we undertake.
          </p>
          <p className="text-gray-700">
          We are a dynamic team of creative professionals dedicated to enhancing brand engagement, visibility, and revenue. Leveraging our expertise in app development, creative design, web development, video production, content writing, and more, we transform concepts into compelling realities.
          </p>
        </div>
      </div>
      
      
    </div>
    </>)
}
export default AboutPage;