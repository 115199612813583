import React from "react";
import Hero from "./Hero";
import AboutSection from "./HomeAbout";
import Services from "./Services";
import HomePortfolio from "./Portfolio";
import UsHome from "./Whyus";
const Home=()=>{
    return(<>
    
        <Hero/>
        <AboutSection/>
        <Services/>
        <HomePortfolio/>
       <UsHome/>
    </>)
};
export default Home;