import React from "react";
import { Link } from "react-router-dom";
const UsHome = () => {
  const cardData = [
    { imgsrc: "images/w1.svg", title: "Expert Members" },
    { imgsrc: "images/w2.svg", title: "100% Satisfaction" },
    { imgsrc: "images/w3.svg", title: "24/7 Support" },
    { imgsrc: "images/w4.svg", title: "Dedicated To Work" },
  ];

  return (
    <div className="flex flex-col items-center justify-start max-w-[1224px] mt-[136px] md:mt-10 mx-auto md:px-0 w-full">
      <div className="flex flex-col gap-9 items-center justify-start w-full">
        <div className="md:w-[60%] sm:w-[100%] m-auto">
                    <h2 className='my-4 font-bold text-center text-3xl sm:text-4xl'>Why Choose Us</h2>
             
               <p className="text-gray-700 text-center">
                <>
                We seek to recruit a proficient team comprising editors, graphic designers, developers, programmers, animators, and content writers, all of whom are seasoned professionals. Our team excels in conceptual thinking, employing an open-minded approach to effectively communicate your brand’s narrative!{" "}
                </>
              </p>
              </div>
        {/* Grid layout for cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8 w-full ">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="shadow-lg rounded-lg overflow-hidden bg-white border border-gray-200"
            >
              <img
                src={card.imgsrc}
                alt={card.title}
                className="w-full h-auto object-cover"
              />
              <p className="text-center py-4 text-lg font-bold text-gray-900">
                {card.title}
              </p>
            </div>
          ))}
        </div>

        {/* Learn More button */}
        <div className="text-center mt-10 mb-10">
          <Link
            to="/Why-us"
            className="home-btn font-semibold min-w-[179px] rounded-[27px] shadow-bs5 text-center text-xl bg-red-400 text-white py-3 px-6 inline-block"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UsHome;
