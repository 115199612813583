import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="w-[90%] m-auto    " style={{ paddingTop: "2%" }}>
        <div className="termfr">
          <div className="terms-left">
            <p className="terms-title1 sm:text-[45px] sm:font-bold md:text-center sm:text-center">
              CORE SPECTATORS
            </p>
            <p className="terms-title2 sm:text-[36px] md:text-center sm:text-center sm:mb-4">
              Privacy Policy
            </p>
            <div className="terms-right mobile">
              {/* Image placeholder */}
            </div>
            <div className="terms-right tablet">
              {/* Image placeholder */}
            </div>
            <p className="text-left sm:text-center text-blue_gray-900 md:mt-0 mt-20 text-lg sm:text-[16px] md:text-[16px] w-full font-lato font-normal">
              At Core Spectators, we prioritize safeguarding your online privacy. This Privacy Policy aims to explain how we handle the personal information collected from users on our website (referred to as the "Site") and through our services. Should you have any inquiries about our Privacy Policy, data collection practices, or wish to report a security concern, please reach out to us at contact@corespectators.com.
            </p>
          </div>
          <div className="terms-right desktop">
            {/* Image placeholder */}
          </div>
        </div>
        <div className="flex flex-col md:gap-[10px] gap-[75px] sm:gap-[10px] items-start justify-start mt-10 mx-auto md:px-0 sm:px-0 w-full">
          <div className="flex flex-col md:gap-10 gap-[111.27px] sm:gap-[10.27px] md:gap-[10.27px] items-start justify-start w-auto md:w-full">
            <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
              <p className="text-left text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">What Information We Collect</p>
              <p className="text-left text-gray-900 text-lg sm:text-[16px] md:text-[16px] font-lato font-normal">
                <span className="text-gray-900 font-lato text-left sm:text-center font-normal">General Data: </span>
                <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal">
                  When you use our services, we automatically collect various types of information. This includes your geographic location, how you interact with our services, details about your device such as its identification number, timestamps of your visits, unique device identifiers, browser type, operating system, Internet Protocol (IP) address, and domain name. We use this information primarily to provide you with relevant content, improve our website’s performance, and enhance your user experience.
                  <br />
                  <span className="text-gray-900 font-lato text-left font-normal">Country Information:</span>
                  <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal"> Core Spectators, as a digital agency, collects information about the country of our users and other relevant data.<br /></span>
                  <span className="text-gray-900 font-lato text-left font-normal">Log Files:</span>
                  <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal">
                    Like most websites, we automatically gather certain information and store it in log files. This includes IP addresses, browser types, internet service providers (ISPs), referring/exit pages, operating systems, timestamps, and clickstream data. We use this data to maintain and enhance the performance of our services.
                    <br />
                  </span>
                  <span className="text-gray-900 font-lato text-left font-normal">Cookies:</span>
                  <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal">
                    Websites utilize "cookies" – small text files sent to your device’s hard drive – to improve user experiences. According to Pakistani law, we require your consent to store non-essential cookies on your device.
                  </span>
                </span>
              </p>
            </div>

            <div className="flex flex-col gap-[37.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
              <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
                <p className="text-left text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">How We Use Your Personal Information</p>
                <p className="text-left  max-w-[1224px] md:max-w-full text-blue_gray_900 text-lg sm:text-[16px] md:text-[16px] font-lato font-normal">The personal information you provide enables us to respond to your requests, enhance our service delivery, and effectively market our offerings. We utilize your personal data in the following ways:</p>
              </div>
            </div>

            <div className="flex flex-col gap-0.5 items-start justify-start w-auto w-full" style={{ paddingBottom: "4%" }}>
              <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] sm:mt-4 items-start justify-start w-auto md:w-full">
                <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
                  <p className="text-center text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">Disclosure of Your Personal Information</p>
                  <p className="text-left max-w-[100%] md:max-w-full md:text-[16px] text-blue_gray_900 text-lg w-full font-lato font-normal">We disclose your personal information as described below and as detailed in this Privacy Policy.</p>
                </div>

                <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] sm:mt-4 items-start justify-start w-auto md:w-full">
                  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
                    <p className="text-left text-4xl sm:text-[22px] md:text-[24px] text-blue_gray-900 w-[auto] sm:w-[90%] font-lato font-semibold">Third Parties Authorized by You: </p>
                    <p className="text-left sm:text-center text-blue_gray_900 text-lg sm:text-[16px] md:text-[16px] w-auto font-lato font-normal">When using our Services, any personal information you provide may be shared with third parties authorized by you to receive such information.</p>
                  </div>

                  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto  md:w-full">
                    <p className="text-left text-4xl sm:text-[22px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">Third-Party Service Providers: </p>
                    <p className="text-left  max-w-[1222px] md:max-w-full sm:text-[16px] md:text-[16px] text-blue_gray_900 text-lg font-lato font-normal">We may share your personal information with third-party service providers to deliver the services offered through our platform, conduct quality assessments, provide technical support, market our services, and perform other tasks on our behalf.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
